import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { AppLink } from 'components/Links';
import { HeroContainer, Section, SectionImage } from 'components/Container';

import HeroImage from 'images/404-page/hero.inline.svg';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const MaintenancePage: FC<LocalizedPageProps> = () => (
  <Layout>
    <Seo />

    <HeroContainer>
      <Section>
        <h1>Sharesight is down for maintenance</h1>

        <p>
          During this time you may not log into or sign up for Sharesight.
          <br />
          We will be back as soon as possible.
        </p>

        <br />
        <br />

        <AppLink
          to="/"
          openInNewTab={false}
          appearance="primary"
          title="Reload and try again"
          asButton
        >
          Try again
        </AppLink>
      </Section>

      <SectionImage image={<HeroImage />} boxShadow={false} />
    </HeroContainer>
  </Layout>
);

export default MaintenancePage;
